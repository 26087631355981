import store from '@/store'
import localizeFilter from '@/filter/localize.filter'
import Journal from '@/config/Journal.dashboard'
export default function Gologin(){
    if(this.$cookies.get('banned'))
        return
    this.$Progress.start()
    axios.get('/users.php', 
        {
            params: 
            {
                login: this.login, 
                pass: this.password, 
                type: 1,
                islogged: this.$cookies.get("loggedIn"),
                changed: this.$cookies.get("changed")
            }
        })
        .then(response => {
            this.$data.answer = response.data['answer'];
            if(this.$data.answer == -3){
                this.$data.key = response.data['key'];
                this.$router.push({name: 'setpassword', params: {login: response.data['login'], key: response.data['key']}})
                Journal(this.login, 'Первый вход в систему, перенаправление на смену пароля')
            }
            if(this.$data.answer == -11){
                localStorage.removeItem('login')
                //localStorage.removeItem('LME')
                localStorage.removeItem('Pt')
                localStorage.removeItem('Pd')
                localStorage.removeItem('Rh')
                localStorage.removeItem('price')
                this.$cookies.remove("loggedIn")
                this.$cookies.remove("role")
                localStorage.removeItem('usd')
                localStorage.removeItem('aud')
                this.$cookies.remove("changed")
                this.$cookies.set("banned", true, Infinity)
                this.$Progress.fail()
                Journal(this.login, 'Аккаунт пользователя заблокирован. Попытка входа в учетную запись на другом устройстве')
            }
            if(this.$data.answer == -4){
                localStorage.removeItem('login')
                //localStorage.removeItem('LME')
                localStorage.removeItem('Pt')
                localStorage.removeItem('Pd')
                localStorage.removeItem('Rh')
                localStorage.removeItem('price')
                this.$cookies.remove("loggedIn")
                this.$cookies.remove("role")
                localStorage.removeItem('usd')
                localStorage.removeItem('aud')
                this.$cookies.remove("changed")
                //this.$cookies.set("banned", true, Infinity)
                this.$Progress.fail()
                Journal(this.login, 'Попытка входа в заблокированный аккаунт')
            }
            if(this.$data.answer == 1){
                // TODO: 
                localStorage.setItem('login', response.data['login'])
                //localStorage.setItem('LME', response.data['LME'])
                localStorage.setItem('Pt', response.data['Pt'])
                localStorage.setItem('Pd', response.data['Pd'])
                localStorage.setItem('Rh', response.data['Rh'])
                localStorage.setItem('price', response.data['price'])
               // localStorage.setItem('usd', response.data['usd'])
               // localStorage.setItem('aud', response.data['aud'])
                localStorage.setItem('userSettings', response.data['LME'] == 1 ? true : false)

                store.state.loggedIn = true                    
                store.state.login = response.data['login']
                //store.state.LME = response.data['LME']
                store.state.userSettings = response.data['userSettings']
                store.state.Pt = response.data['Pt']
                store.state.Pd = response.data['Pd']
                store.state.Rh = response.data['Rh']
                store.state.price = response.data['price']
                store.state.role = response.data['role']
                store.state.email = response.data['email']
                //store.state.favourite = response.data['favourite'].split('++')

                if(response.data['usd'] === null){
                    store.state.usd = store.state.defusd
                    localStorage.setItem('usd', store.state.defusd)
                }
                else{
                    store.state.usd = response.data['usd']
                    localStorage.setItem('usd', response.data['usd'])
                }
                if(response.data['aud'] === null){
                    store.state.aud = store.state.defaud
                    localStorage.setItem('aud', store.state.defaud)
                }
                else{
                    store.state.aud = response.data['aud']
                    localStorage.setItem('aud', response.data['aud'])
                }
                
               // store.state.aud = response.data['aud'] === '' ? store.state.defaud :  response.data['aud']

                this.$cookies.set("loggedIn", true, Infinity)
                this.$cookies.set("role",response.data['role'], Infinity)
                this.$cookies.remove("changed")
                localStorage.setItem("login", response.data['login']);
                Journal(this.login, 'Успешный вход в систему')
                this.$Progress.finish()
                this.$router.push('/search')
            }
        })
        .catch(error =>{
            console.log(error)
            UIkit.notification({message: localizeFilter('Fatal_error_axios'), status:'danger'})
            this.$Progress.fail()
        })
}