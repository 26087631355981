<template>
    <div>
        <div v-if="this.$data.answer === -1" class="uk-alert-danger" uk-alert>
            <p>{{'LoginErrorNotFound' | localize}}</p>
        </div>
        <div v-else-if="this.$data.answer === -2" class="uk-alert-danger" uk-alert>
            <p>{{'CheckPas' | localize}}</p>
        </div>
        <div v-else-if="this.$data.answer === -4" class="uk-alert-danger" uk-alert>
            <p>{{'Blocked' | localize}}</p>
        </div>
         <div v-else-if="this.$data.answer === -10" class="uk-alert-danger" uk-alert>
            <p>{{'FatalError' | localize}}</p>
        </div>
        <div v-else-if="this.$data.answer === -11" class="uk-alert-danger" uk-alert>
            <p>{{'FromAnotherDevice' | localize}}</p>
        </div>
        <form class="toggle-class uk-width-medium uk-padding-small" @submit.prevent="Gologin">
            <fieldset class="uk-fieldset">
                <div class="uk-margin-small">
                    <div class="uk-inline uk-width-1-1">
                        <span class="uk-form-icon uk-form-icon-flip" data-uk-icon="icon: phone"></span>
                        <input class="uk-input" id='phone' v-model.trim="login" required :placeholder="localizeFilter('Login')" type="text">
                    </div>
                </div>
                <div class="uk-margin-small">
                    <div class="uk-inline uk-width-1-1">
                        <span class="uk-form-icon uk-form-icon-flip" data-uk-icon="icon: lock"></span>
                        <input class="uk-input" id='password' v-model="password" required :placeholder="localizeFilter('Password')" type="password">
                    </div>
                </div>
                <div class="uk-margin-top">
                    <button class="uk-button uk-button-primary uk-border-pill uk-width-1-1">{{'Enter' | localize}}</button>
                </div>
            </fieldset>
        </form>
    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import localizeFilter from "@/filter/localize.filter"
import store from '@/store'
import Gologin from '@/config/Login.general'

export default {
    metaInfo:{
        title: localizeFilter('TitleLogin')
    },
    name: 'login',
    data: () => ({
        login: '',
        password: '',
        answer: '',
        key: '',
        authenticated: false
    }),
    methods:{
        Gologin,
        localizeFilter
    },
    beforeMount() {
        if(this.$cookies.get("loggedIn")){
            this.$router.push('/search')
        }
    }
        
}
</script>